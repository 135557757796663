import {useState, useContext} from "react";
import {MainContext} from '../contexts/MainContext'
import { toast } from 'react-toastify';
function useHttpHook() {
    const {jwt, toogleLoading} = useContext(MainContext);
    // const [baseUrl, changeUrl] = useState('http://localhost:3000');
    const [baseUrl, changeUrl] = useState('https://api.aminhacarteira.com.br');
    async function httpRequest(method, path, data = null) {
        toogleLoading(true);
        try {
            const response = await fetch(baseUrl + path, {
                method: method, // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                },
                body: data ? JSON.stringify(data) : null// body data type must match "Content-Type" header
            });
            console.log(response);
            if(response.status >= 400){
                throw response;
            }
            toogleLoading(false);
            if(method === 'PUT' || method === 'PATCH' || method === 'DELETE'){
                return '204';
            }else{
                return response.json();
            }
        } catch (e) {
            console.log(e);
            toogleLoading(false);
            toast.error('Ocorreu um erro no sistema')
            throw e;
        }

    }

    return {httpRequest}
}

export default useHttpHook