import React, {useState, useEffect} from "react"
import io from "socket.io-client";

const MainContext = React.createContext();

function MainContextProvider({children}) {
    const [jwt, setJwt] = useState('');
    const [userData, setUserData] = useState({});
    const [isLoading, toogleLoading] = useState(false);
    const [loaded, changeLoaded] = useState(false);
    const [LANGUAGE, changeLanguage] = useState('pt-BR');
    // const [socket] = useState(io('http://localhost:3000', {  transports: ['websocket']}));
    const [socket] = useState(io('wss://api.aminhacarteira.com.br', { path: '/monitor-01', transports: ['websocket']}));
    const [userStocks, setUserStocks] = useState([]);

    useEffect(() => {
        let loggedJwt = window.localStorage.getItem('jwt');
        if (loggedJwt) {
            loggedJwt = loggedJwt.replace("\"", '');
            loggedJwt = loggedJwt.replace("\"", '');
            setJwt(loggedJwt);
            setUserData(parseJwt(loggedJwt));
        }
        return changeLoaded(true);

    }, []);

    useEffect(() => {
        return setUserData(parseJwt(jwt));
    }, [jwt]);

    function parseJwt(jwt) {
        try {
            return JSON.parse(atob(jwt.split('.')[1]));
        } catch (e) {
            return null;
        }
    }

    if (loaded) {
        return (
            <MainContext.Provider value={{
                jwt, setJwt,
                userData, setUserData,
                isLoading, toogleLoading,
                LANGUAGE, changeLanguage,
                socket,
                userStocks, setUserStocks
            }}>
                {children}
            </MainContext.Provider>
        )
    } else {
        return (
            <div></div>
        )
    }
}

export {MainContextProvider, MainContext}